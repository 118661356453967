/* Component Dependencies */
var clickToAction = require('templates/clickToAction.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
  type: 'clickToAction',
  template: {
    'clickToAction': clickToAction
  }
});
